import { Range } from "src/types";
import { CellEntity } from "../CellEntity";
import { TableEntity } from "../TableEntity";
import { Field, RouteRestriction } from "../../Fields";
import { Entity } from "../Entity";

export class Comment extends CellEntity {
  private route_restriction: RouteRestriction;
  constructor(
    id: string,
    range: Range,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE",
    global: boolean = false,
    parentTable: TableEntity,
    override?: boolean
  ) {
    super(
      id,
      range,
      "Comment",
      sheetIndex,
      coordinate,
      sheetName,
      name,
      status,
      global,
      parentTable,
      override
    );
    this.route_restriction = new RouteRestriction("route_restriction", false);
  }

  public getFields(): Field[] {
    return [this.route_restriction];
  }

  public getNameField(control: any): React.ReactNode {
    return this.global ? super.getNameField(control) : null;
  }

  public toJson(): any {
    return {
      ...super.toJson(),
      routeRestrictions: this.route_restriction.getValue(),
    };
  }

  public fromJson(json: any, map: Map<string, Entity>): void {
    super.fromJson(json, map);
    this.route_restriction.setValue(
      json.routeRestrictions || json.routeRestriction
    );
  }
}
