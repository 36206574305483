import { TreeViewBaseItem } from "@mui/x-tree-view";
import { ExtendedTreeItemProps } from "src/components/TreeView";
import { CellEntity, TableEntity } from "src/libs/Entities";
import { Range } from "src/types";
import { Hazardous, OceanFee, Service, TransitTime, VIA } from "../../Headers";

export class Routes extends TableEntity {
  private ocean_fees: OceanFee[];
  private service: Service | null;
  private hazardous: Hazardous | null;
  private transit_time: TransitTime | null;
  private via: VIA | null;

  public constructor(
    id: string,
    range: Range,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE"
  ) {
    super(
      id,
      range,
      "Routes Table",
      sheetIndex,
      coordinate,
      sheetName,
      name,
      status
    );
    this.ocean_fees = [];
    this.service = null;
    this.hazardous = null;
    this.transit_time = null;
    this.via = null;
  }

  public allowDuplicate(header: CellEntity): boolean {
    if (header instanceof OceanFee) {
      return true;
    } else if (header instanceof Service) {
      return false;
    } else if (header instanceof Comment) {
      return false;
    } else if (header instanceof Hazardous) {
      return false;
    } else if (header instanceof TransitTime) {
      return false;
    } else if (header instanceof VIA) {
      return false;
    } else {
      return super.allowDuplicate(header);
    }
  }

  public getHeaders(): CellEntity[] {
    return [
      ...super.getHeaders(),
      ...this.ocean_fees,
      this.service,
      this.hazardous,
      this.transit_time,
      this.via,
    ].filter((cell) => cell !== null) as CellEntity[];
  }

  public getHeadersOptions(): string[] {
    return [
      ...super.getHeadersOptions(),
      "OCEAN Fee",
      this.service ? null : "Service",
      this.hazardous ? null : "Hazardous",
      this.transit_time ? null : "Transit time",
      this.via ? null : "VIA",
    ].filter((cell) => cell !== null) as string[];
  }

  public submitHeader(header: CellEntity): void {
    if (header instanceof OceanFee && !this.ocean_fees.includes(header)) {
      this.ocean_fees.push(header);
    } else if (header instanceof Service) {
      this.service = header;
    } else if (header instanceof Hazardous) {
      this.hazardous = header;
    } else if (header instanceof TransitTime) {
      this.transit_time = header;
    } else if (header instanceof VIA) {
      this.via = header;
    } else {
      super.submitHeader(header);
    }
  }

  public removeHeader(header: CellEntity): void {
    if (header instanceof Service && this.service === header) {
      this.service = null;
    } else if (header instanceof TransitTime && this.transit_time === header) {
      this.transit_time = null;
    } else if (header instanceof OceanFee) {
      this.ocean_fees = this.ocean_fees.filter((fee) => fee !== header);
    } else if (header instanceof VIA && this.via === header) {
      this.via = null;
    } else if (header instanceof Hazardous && this.hazardous === header) {
      this.hazardous = null;
    } else {
      super.removeHeader(header);
    }
  }

  protected headersToTreeItem(): TreeViewBaseItem<ExtendedTreeItemProps>[] {
    const res: TreeViewBaseItem<ExtendedTreeItemProps>[] = [];
    const headerChildren: TreeViewBaseItem<ExtendedTreeItemProps>[] = [];
    for (const fee of this.ocean_fees) {
      headerChildren.push(fee.toTreeItem(this.id));
    }
    res.push({
      id: `${this.id}-ocean_fees`,
      label: "OCEAN Fees",
      type: "TYPE",
      parentId: this.id,
      children: headerChildren,
    });
    if (this.service !== null) {
      res.push({
        id: `${this.id}-service`,
        label: "Service",
        type: "TYPE",
        parentId: this.id,
        children: [this.service.toTreeItem(this.id)],
      });
    }
    if (this.hazardous !== null) {
      res.push({
        id: `${this.id}-hazardous`,
        label: "Hazardous",
        type: "TYPE",
        parentId: this.id,
        children: [this.hazardous.toTreeItem(this.id)],
      });
    }
    if (this.transit_time !== null) {
      res.push({
        id: `${this.id}-transit_time`,
        label: "Transit Time",
        type: "TYPE",
        parentId: this.id,
        children: [this.transit_time.toTreeItem(this.id)],
      });
    }
    if (this.via !== null) {
      res.push({
        id: `${this.id}-via`,
        label: "VIA",
        type: "TYPE",
        parentId: this.id,
        children: [this.via.toTreeItem(this.id)],
      });
    }
    return [...super.headersToTreeItem(), ...res];
  }

  public isValid(): boolean {
    return this.ocean_fees.length > 0 && super.isValid();
  }

  public getFees(): CellEntity[] {
    return [...this.ocean_fees, ...super.getFees()];
  }
}
