import { Range } from "src/types";
import { CellEntity, Entity, TableEntity } from "../../Entities";
import {
  DirectKey,
  Field,
  Port,
  RangeSelection,
  Seperator,
  ViaPort,
  TransshipmentKey,
} from "../../Fields";

export class VIA extends CellEntity {
  private direct_key: DirectKey;
  private transshipment_key: TransshipmentKey;
  //private seperator: Seperator;
  private via_port: ViaPort;
  private port: Port;
  constructor(
    id: string,
    range: Range,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE",
    parentTable: TableEntity,
    override?: boolean
  ) {
    super(
      id,
      range,
      "VIA",
      sheetIndex,
      coordinate,
      sheetName,
      name,
      status,
      false,
      parentTable,
      override
    );
    this.direct_key = new DirectKey("direct_key", false);
    this.transshipment_key = new TransshipmentKey("transshipment_key", false);
    //this.seperator = new Seperator("seperator", false);
    this.via_port = new ViaPort("via_port", false, true);
    this.port = new Port("port", false);
  }

  public getFields(): Field[] {
    return this.type === "Transshipment port"
      ? [this.port]
      : [this.direct_key, this.transshipment_key, this.via_port, this.port];
  }

  public toJson(): any {
    const portValue = this.port.getValue();
    return {
      ...super.toJson(),
      directKey: this.direct_key.getValue(),
      transshipmentKey: this.transshipment_key.getValue(),
      //seperator: this.seperator.getValue(),
      value: portValue?.map((p: any) => p.value),
      scope: portValue?.length > 0 ? "global" : "local",
      exstractFromCol: this.via_port.getValue()?.range?.c,
      metadata: {
        ...super.toJson().metadata,
        ports: portValue,
        viaPort: this.via_port.getValue(),
      },
    };
  }

  public getNameField(control: any): React.ReactNode {
    return null;
  }

  public fromJson(json: any, map: Map<string, Entity>): void {
    super.fromJson(json, map);
    this.direct_key.setValue(json.directKey);
    this.transshipment_key.setValue(json.transshipmentKey);
    //this.seperator.setValue(json.seperator);
    this.port.setValue(json.metadata.ports);
    if (json.metadata.viaPort) {
      this.via_port.setValue(new RangeSelection(json.metadata.viaPort.range));
    }
  }
}
