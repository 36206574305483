import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useMemo, useState } from "react";
import TreeView, { ExtendedTreeItemProps } from "src/components/TreeView";
import useEntities from "./EntitiesContext";
import { BORDER_COLOR } from "src/libs/colors";
import { CellEntity, Entity, TableEntity } from "src/libs/Entities";
import { TreeViewBaseItem } from "@mui/x-tree-view";
import { getExcelCoordinate } from "src/libs/utils";
import { v4 as uuidv4 } from "uuid";

const Accordion2 = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const EntitiesPanel = ({
  sheetsNames,
  errors,
}: {
  sheetsNames: string[];
  errors: any[];
}) => {
  const {
    globalEntities,
    tableEntities,
    TableManage: { entity },
  } = useEntities();

  const globalItems = (() => {
    const sheets = globalEntities
      .map((entity: CellEntity) => ({
        name: entity.sheetName,
        index: entity.sheetIndex,
      }))
      .filter(
        (obj, index, self) =>
          index === self.findIndex((t) => t.index === obj.index)
      );
    return sheets.map((sheet) =>
    ({
      id: `${sheet.name}-${sheet.index}`,
      label: sheet.name,
      type: "SHEET",
      children: CellEntity.globals()
        .map((type) => ({
          id: `${sheet.name}-${sheet.index}-${type}-global`,
          label: type,
          type: "TYPE",
          children: globalEntities
            .filter((entity: CellEntity) => entity.type === type && entity.sheetIndex === sheet.index)
            .map((entity: CellEntity) => entity.toTreeItem()),
        }))
        .filter(
          (type) => type.children.length > 0
        )
    })) as TreeViewBaseItem<ExtendedTreeItemProps>[];
  })() as TreeViewBaseItem<ExtendedTreeItemProps>[]
    ;
  const tabsItems = (() => {
    const sheets = tableEntities
      .map((entity: TableEntity) => ({
        name: entity.sheetName,
        index: entity.sheetIndex,
      }))
      .filter(
        (obj, index, self) =>
          index === self.findIndex((t) => t.index === obj.index)
      );
    return sheets.map(({ name, index }) => ({
      id: `${name}-${index}`,
      label: name,
      type: "SHEET",
      children: tableEntities
        .filter((entity: TableEntity) => entity.sheetIndex === index)
        .map((entity: TableEntity) => entity.toTreeItem()),
    }));
  })() as TreeViewBaseItem<ExtendedTreeItemProps>[];

  const errorsItems: TreeViewBaseItem<ExtendedTreeItemProps>[] = useMemo(() => {
    const sheets = errors
      .map((error: any) => ({ name: error.sheetName, index: error.sheetIndex }))
      .filter(
        (value: any, index: number, self: any[]) =>
          self.findIndex((v: any) => v.index === value.index) === index
      );
    return sheets.map(({ name, index }: any) => ({
      id: `${name}-${index}`,
      label: name,
      type: "SHEET",
      children: errors
        .filter((error: any) => error.sheetIndex === index)//filter by sheet index
        .filter(
          (error: any, index: number, self: any[]) =>
            self.findIndex((v: any) => v.error_message === error.error_message) === index
        )// filter by error message remove duplicates
        .map((eMsg: any) => ({
          id: `${eMsg.sheetIndex}+${eMsg.error_message}`,
          label: `${eMsg.type}: ${eMsg.error_message}`,
          type: "TYPE",
          children: errors.filter((e: any) => e.error_message === eMsg.error_message && e.sheetIndex === index)
            .map((error: any) => ({
              id: `${error.row}:${error.col}-${error.sheetIndex}+${uuidv4()}`,
              label: `${getExcelCoordinate(error.range)}`,
              type: "ERROR",
              error_message: error.error_message,
              entity: {
                range: error.range,
                sheetIndex: error.sheetIndex,
                sheetName: error.sheetName,
              } as Entity, // only to use show entity call back
            })),
        }))

    }));
  }, [errors]);

  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (entity) {
      setExpanded(true);
    }
  }, [entity, tableEntities]);

  const handleExpand = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(!expanded);
  };

  // handle back to upload form
  const createNewSession = () => {
    window.location.reload();
  };
  return (
    <Card
      sx={{
        height: 1,
        elevation: 4,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardHeader title={<Typography variant="h6">Tags Manager</Typography>} />
      <Divider />
      <CardContent sx={{ maxHeight: 1, overflow: "auto", p: 0 }}>
        <Accordion2>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="global-header">
            <Typography sx={{ fontWeight: "bold" }}>Global</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TreeView items={globalItems} />
          </AccordionDetails>
        </Accordion2>
        <Accordion2 expanded={expanded} onChange={handleExpand}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="tabs-header">
            <Typography sx={{ fontWeight: "bold" }}>Tabs</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TreeView items={tabsItems} />
          </AccordionDetails>
        </Accordion2>
        {errors.length > 0 && (
          <Accordion2 defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="errors-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>Errors</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TreeView items={errorsItems} />
            </AccordionDetails>
          </Accordion2>
        )}
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "0.9rem", mb: 2 }}>
            Not active
          </Typography>
          <Stack spacing={0.5}>
            {sheetsNames.map((sheetName, index) => (
              <Typography
                key={sheetName + index}
                sx={{ opacity: 0.4, fontSize: "0.9rem" }}
              >
                {sheetName}
              </Typography>
            ))}
          </Stack>
        </Box>
      </CardContent>
      <CardActions
        disableSpacing
        sx={{ mt: "auto", borderTop: 1, borderColor: BORDER_COLOR }}
      >
        <Button
          onClick={createNewSession}
          sx={{ left: "1vw", fontWeight: 600 }}
        >
          + Create New Session
        </Button>
      </CardActions>
    </Card>
  );
};

export default EntitiesPanel;
