import { TextInput } from "src/components/Inputs";
import { Field } from "./Field";

export class TransshipmentKey extends Field {
  value: string | undefined;

  constructor(fieldName: string, required: boolean = false) {
    super(fieldName, "Transshipment Key", required);
    this.value = undefined;
  }

  public getValue(): string | undefined {
    return this.value;
  }

  public setValue(value: string | undefined): void {
    this.value = value;
  }
  public render(control: any, setValue: any, getValues: any): React.ReactNode {
    return (
      <TextInput
        label={this.label}
        fieldName={this.fieldName}
        control={control}
        rules={{ required: this.required && "Transshipment Key is mandatory!" }}
      />
    );
  }
}
