import { Range } from "src/types";
import { CellEntity } from "./CellEntity";
import { Entity } from "./Entity";
import {
  Condition,
  Comment,
  Currency,
  FreeDaysPOD,
  FreeDaysPOL,
  GeneralFeeAIR,
  GeneralFeeFCL,
  GeneralFeeLCL,
  POD,
  POL,
  ValidityEnd,
  ValidityStart,
  VolumetricRatio,
} from "./Globals";
import {
  AirFreightFee,
  Hazardous,
  Range as RangeH,
  OceanFeeFCL,
  OceanFeeLCL,
  Service,
  TransitTime,
  VIA,
} from "./Headers";
import { TableEntity } from "./TableEntity";
import {
  Routes,
  RoutesAIR,
  RoutesLCL,
  Transshipment,
  TransshipmentAIR,
  TransshipmentLCL,
} from "./Tables";

export class Factory {
  public static factory(
    id: string,
    range: Range,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE",
    type: string = "",
    global: boolean = false,
    parentTable?: TableEntity,
    override?: boolean
  ): Entity {
    if (!global && parentTable && sheetIndex !== parentTable.sheetIndex) {
      throw new Error("Header must be in the same sheet as the table");
    }
    switch (type) {
      // globals cases
      case "Condition":
        return new Condition(
          id,
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          global,
          parentTable,
          override
        );
      case "Currency":
        return new Currency(
          id,
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          global,
          parentTable,
          override
        );
      case "POL":
        return new POL(
          id,
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          global,
          parentTable,
          override
        );
      case "Comment":
        return new Comment(
          id,
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          global,
          parentTable!,
          override
        );
      case "POD":
        return new POD(
          id,
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          global,
          parentTable,
          override
        );
      case "Validity start":
        return new ValidityStart(
          id,
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          global,
          parentTable,
          override
        );
      case "Validity end":
        return new ValidityEnd(
          id,
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          global,
          parentTable,
          override
        );
      case "General Fee":
        switch (Factory.FILE_TYPE) {
          case "LCL":
            return new GeneralFeeLCL(
              id,
              range,
              sheetIndex,
              coordinate,
              sheetName,
              name,
              status,
              global,
              parentTable,
              override
            );
          case "AIR":
            return new GeneralFeeAIR(
              id,
              range,
              sheetIndex,
              coordinate,
              sheetName,
              name,
              status,
              global,
              parentTable,
              override
            );
          default:
            return new GeneralFeeFCL(
              id,
              range,
              sheetIndex,
              coordinate,
              sheetName,
              name,
              status,
              global,
              parentTable,
              override
            );
        }
      case "Free Days(POL)":
        return new FreeDaysPOL(
          id,
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          global,
          parentTable,
          override
        );
      case "Free Days(POD)":
        return new FreeDaysPOD(
          id,
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          global,
          parentTable,
          override
        );
      case "Volumetric Ratio":
        return new VolumetricRatio(
          id,
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          global,
          parentTable,
          override
        );

      // headers cases
      case "Transshipment port":
        const via = new VIA(
          id,
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          parentTable!,
          override
        );
        via.type = "Transshipment port";
        return via;
      case "Hazardous":
        return new Hazardous(
          id,
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          parentTable!,
          override
        );
      case "VIA":
        return new VIA(
          id,
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          parentTable!,
          override
        );
      case "OCEAN Fee":
        if (Factory.FILE_TYPE === "LCL") {
          return new OceanFeeLCL(
            id,
            range,
            sheetIndex,
            coordinate,
            sheetName,
            name,
            status,
            parentTable!,
            override
          );
        } else {
          return new OceanFeeFCL(
            id,
            range,
            sheetIndex,
            coordinate,
            sheetName,
            name,
            status,
            parentTable!,
            override
          );
        }
      case "AirFreight Fee":
        return new AirFreightFee(
          id,
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          parentTable!,
          override
        );
      case "Transit time":
        return new TransitTime(
          id,
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          parentTable!,
          override
        );
      case "Service":
        return new Service(
          id,
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          parentTable!,
          override
        );
      case "Range":
        return new RangeH(
          id,
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          parentTable!,
          override
        );

      // tables cases
      case "Routes Table":
        switch (Factory.FILE_TYPE) {
          case "LCL":
            return new RoutesLCL(
              id,
              range,
              sheetIndex,
              coordinate,
              sheetName,
              name,
              status
            );
          case "AIR":
            return new RoutesAIR(
              id,
              range,
              sheetIndex,
              coordinate,
              sheetName,
              name,
              status
            );
          default:
            return new Routes(
              id,
              range,
              sheetIndex,
              coordinate,
              sheetName,
              name,
              status
            );
        }
      case "Transshipment":
        switch (Factory.FILE_TYPE) {
          case "LCL":
            return new TransshipmentLCL(
              id,
              range,
              sheetIndex,
              coordinate,
              sheetName,
              name,
              status
            );
          case "AIR":
            return new TransshipmentAIR(
              id,
              range,
              sheetIndex,
              coordinate,
              sheetName,
              name,
              status
            );
          default:
            return new Transshipment(
              id,
              range,
              sheetIndex,
              coordinate,
              sheetName,
              name,
              status
            );
        }
      default:
        return new CellEntity(
          id,
          range,
          type,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          global,
          parentTable,
          override
        );
    }
  }

  public static FILE_TYPE = "FCL";
}
