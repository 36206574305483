import { TreeViewBaseItem } from "@mui/x-tree-view";
import { ExtendedTreeItemProps } from "src/components/TreeView";
import { Range } from "src/types";
import { CellEntity } from "../../CellEntity";
import { VolumetricRatio } from "../../Globals";
import { Range as RangeH } from "../../Headers";
import { Transshipment } from "./Transshipment";

export class TransshipmentAIR extends Transshipment {
  private volumetric_ratio: VolumetricRatio | null;
  public ranges: RangeH[];

  public constructor(
    id: string,
    range: Range,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE"
  ) {
    super(id, range, sheetIndex, coordinate, sheetName, name, status);
    this.volumetric_ratio = null;
    this.ranges = [];
  }

  public allowDuplicate(header: CellEntity): boolean {
    if (header instanceof VolumetricRatio) {
      return false;
    } else if (header instanceof RangeH) {
      return true;
    } else {
      return super.allowDuplicate(header);
    }
  }

  public getHeaders(): CellEntity[] {
    return [
      ...super.getHeaders(),
      this.volumetric_ratio,
      ...this.ranges,
    ].filter((cell) => cell !== null) as CellEntity[];
  }

  public getHeadersOptions(): string[] {
    return [
      ...super.getHeadersOptions(),
      this.volumetric_ratio ? null : "Volumetric Ratio",
      "Range",
    ]
      .filter((cell) => cell !== null)
      .map((type) =>
        type === "OCEAN Fee" ? "AirFreight Fee" : type
      ) as string[];
  }

  public submitHeader(header: CellEntity): void {
    if (header instanceof VolumetricRatio) {
      this.volumetric_ratio = header;
    } else if (header instanceof RangeH && !this.ranges.includes(header)) {
      this.ranges.push(header);
    } else {
      super.submitHeader(header);
    }
  }

  public removeHeader(header: CellEntity): void {
    if (header instanceof VolumetricRatio && this.volumetric_ratio === header) {
      this.volumetric_ratio = null;
    } else if (header instanceof RangeH) {
      this.ranges = this.ranges.filter((range) => range !== header);
    } else {
      super.removeHeader(header);
    }
  }

  protected headersToTreeItem(): TreeViewBaseItem<ExtendedTreeItemProps>[] {
    const res: TreeViewBaseItem<ExtendedTreeItemProps>[] = [];
    if (this.volumetric_ratio !== null) {
      res.push({
        id: `${this.id}-volumetric-ratio`,
        label: "Volumetric Ratio",
        type: "TYPE",
        parentId: this.id,
        children: [this.volumetric_ratio.toTreeItem(this.id)],
      });
    }
    if (this.ranges.length > 0) {
      const headerChildren: TreeViewBaseItem<ExtendedTreeItemProps>[] = [];
      for (const range of this.ranges) {
        headerChildren.push(range.toTreeItem(this.id));
      }
      res.push({
        id: `${this.id}-ranges`,
        label: "Ranges",
        type: "TYPE",
        parentId: this.id,
        children: headerChildren,
      });
    }
    return [...super.headersToTreeItem(), ...res];
  }

  public getGlobals(): Record<string, any> {
    return {
      volumetric_ratio: this.volumetric_ratio?.global
        ? this.volumetric_ratio
        : null,
      ...super.getGlobals(),
    };
  }

  public getGlobalsOptions(globals: CellEntity[]): Record<
    string,
    {
      isMulti: boolean;
      label: string;
      options: CellEntity[];
    }
  > {
    return {
      volumetric_ratio: {
        label: "Volumetric Ratio",
        isMulti: false,
        options: globals.filter((global) => global instanceof VolumetricRatio),
      },
      ...super.getGlobalsOptions(globals),
    };
  }

  public resetGlobals(): void {
    this.volumetric_ratio = !this.volumetric_ratio?.global
      ? this.volumetric_ratio
      : null;
    super.resetGlobals();
  }
}
