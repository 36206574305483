import { TreeViewBaseItem } from "@mui/x-tree-view";
import { ExtendedTreeItemProps } from "src/components/TreeView";
import { Range } from "src/types";
import { CellEntity, Entity, POL, TableEntity } from "../../../Entities";
import {
  Hazardous,
  OceanFee,
  Service,
  TransitTime,
  VIA,
} from "../../Headers";
import { Routes } from "../Routes/index";

export class Transshipment extends TableEntity {
  private ocean_fees: OceanFee[];
  private service: Service | null;
  private hazardous: Hazardous | null;
  private transit_time: TransitTime | null;
  private via: VIA | null;
  public routes: Routes | null;

  public constructor(
    id: string,
    range: Range,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE"
  ) {
    super(
      id,
      range,
      "Transshipment",
      sheetIndex,
      coordinate,
      sheetName,
      name,
      status
    );
    this.ocean_fees = [];
    this.service = null;
    this.hazardous = null;
    this.transit_time = null;
    this.via = null;
    this.routes = null;
  }

  public allowDuplicate(header: CellEntity): boolean {
    if (header instanceof OceanFee) {
      return true;
    } else if (header instanceof Service) {
      return false;
    } else if (header instanceof Comment) {
      return false;
    } else if (header instanceof Hazardous) {
      return false;
    } else if (header instanceof TransitTime) {
      return false;
    }
    else if (header instanceof VIA) {
      return false;
    }
    else {
      return super.allowDuplicate(header);
    }
  }

  public getHeaders(): CellEntity[] {
    return [
      ...super.getHeaders(),
      ...this.ocean_fees,
      this.service,
      this.hazardous,
      this.transit_time,
      this.via,
    ].filter((cell) => cell !== null) as CellEntity[];
  }

  public getHeadersOptions(): string[] {
    return [
      this.via ? null : "Transshipment port",
      ...super
        .getHeadersOptions()
        .filter((type) => ((type === "POL" || type === "POD") ? this.pol === null && this.pod === null : type)),
      "OCEAN Fee",
      this.service ? null : "Service",
      this.hazardous ? null : "Hazardous",
      this.transit_time ? null : "Transit time",
    ].filter((cell) => cell !== null) as string[];
  }

  public submitHeader(header: CellEntity): void {
    if (header instanceof OceanFee && !this.ocean_fees.includes(header)) {
      this.ocean_fees.push(header);
    } else if (header instanceof Service) {
      this.service = header;
    } else if (header instanceof Hazardous) {
      this.hazardous = header;
    } else if (header instanceof TransitTime) {
      this.transit_time = header;
    }
    else if (header instanceof VIA) {
      this.via = header;
      this.via.type = "Transshipment port";
    }
    else {
      super.submitHeader(header);
    }
  }

  public removeHeader(header: CellEntity): void {
    if (header instanceof Service && this.service === header) {
      this.service = null;
    } else if (header instanceof TransitTime && this.transit_time === header) {
      this.transit_time = null;
    } else if (header instanceof OceanFee) {
      this.ocean_fees = this.ocean_fees.filter((fee) => fee !== header);
    }
    else if (header instanceof VIA && this.via === header) {
      this.via = null;
    }
    else if (header instanceof Hazardous && this.hazardous === header) {
      this.hazardous = null;
    } else {
      super.removeHeader(header);
    }
  }

  protected headersToTreeItem(): TreeViewBaseItem<ExtendedTreeItemProps>[] {
    const res: TreeViewBaseItem<ExtendedTreeItemProps>[] = [];
    const headerChildren: TreeViewBaseItem<ExtendedTreeItemProps>[] = [];
    for (const fee of this.ocean_fees) {
      headerChildren.push(fee.toTreeItem(this.id));
    }
    res.push({
      id: `${this.id}-ocean_fees`,
      label: "OCEAN Fees",
      type: "TYPE",
      parentId: this.id,
      children: headerChildren,
    });
    if (this.service !== null) {
      res.push({
        id: `${this.id}-service`,
        label: "Service",
        type: "TYPE",
        parentId: this.id,
        children: [this.service.toTreeItem(this.id)],
      });
    }
    if (this.hazardous !== null) {
      res.push({
        id: `${this.id}-hazardous`,
        label: "Hazardous",
        type: "TYPE",
        parentId: this.id,
        children: [this.hazardous.toTreeItem(this.id)],
      });
    }
    if (this.transit_time !== null) {
      res.push({
        id: `${this.id}-transit_time`,
        label: "Transit Time",
        type: "TYPE",
        parentId: this.id,
        children: [this.transit_time.toTreeItem(this.id)],
      });
    }
    const result = [...super.headersToTreeItem().filter((item) => (item.label === "POL" || item.label === "POD") && item.children?.length === 0 ? this.pol == null && this.pod === null : true), ...res];
    if (this.via !== null) {
      result.unshift({
        id: `${this.id}-via`,
        label: "Transshipment port",
        type: "TYPE",
        parentId: this.id,
        children: [this.via.toTreeItem(this.id)],
      },);
    }
    // for (const item of result) {
    //   if (item.label === "POL") {
    //     item.label = "Transshipment port";
    //   }
    // }
    return result;
  }

  public isValid(): boolean {
    return this.ocean_fees.length > 0 && ((this.pod !== null && this.pol === null) || (this.pol !== null && this.pod === null)) &&
      this.currency !== null &&
      this.validity_start !== null &&
      this.validity_end !== null && this.via !== null;
  }

  public getGlobalsOptions(globals: CellEntity[]): Record<
    string,
    {
      isMulti: boolean;
      label: string;
      options: CellEntity[];
    }
  > {
    const res = super.getGlobalsOptions(globals);
    //delete res["pol"];
    return res;
  }
  public getFees(): CellEntity[] {
    return [...this.ocean_fees, ...super.getFees()];
  }

  public toJson() {
    return {
      ...super.toJson(),
      metadata: {
        ...super.toJson().metadata,
        routesId: this.routes?.id,
      },
    };
  }

  public fromJson(json: any, map: Map<string, Entity>): void {
    super.fromJson(json, map);
    if (json.metadata.routesId && map.has(json.metadata.routesId)) {
      this.routes = map.get(json.metadata.routesId) as Routes;
    }
  }
}
