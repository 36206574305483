import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AssistantIcon from "@mui/icons-material/Assistant";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { SelectInput, TextInput } from "src/components/Inputs";
import RightSideModal from "src/components/RightSideModal";
import { BORDER_COLOR } from "src/libs/colors";
import { CellEntity } from "src/libs/Entities";
import { Transshipment } from "src/libs/Entities/Tables";
import useEntities from "./EntitiesContext";
import ManageCellForm from "./ManageCellForm";

interface ManageTableModalProps {
  open: boolean;
  handleClose: () => void;
}

const ManageTableModal: React.FC<ManageTableModalProps> = ({
  open,
  handleClose,
}) => {
  const [step, setStep] = useState(0);
  const [gotIt, setGotIt] = useState(false);

  const { handleSubmit, control, reset, setValue, setError } = useForm();

  const Next = () => setStep(step + 1);

  const {
    globalEntities,
    handleAddTableEntitySubmit,
    tableEntities,
    TableManage: { entity, revertChanges },
    CellManage,
    state,
    setState,
    handleDeleteTableEntity,
  } = useEntities();

  const Close = useCallback(
    (restoreData?: boolean) => {
      revertChanges();
      handleClose();
      setState("NORMAL");
      setStep(0);
      reset();
    },
    [revertChanges, handleClose, setState, reset]
  );

  const newTable = useMemo(() => entity?.name === "", [entity]);

  const globalsMemo = useMemo(
    () => entity?.getGlobalsOptions(globalEntities) ?? [],
    [entity, globalEntities]
  );

  const fillForm = useCallback(() => {
    if (entity) {
      setValue("name", entity.name);
      if (entity instanceof Transshipment) {
        setValue("Routes Table", entity.routes);
      }
      const globals = entity.getGlobals();
      for (const global of Object.keys(globals)) {
        setValue(global, globals[global]);
      }
    }
  }, [entity, setValue]);
  useLayoutEffect(() => {
    fillForm();
  }, [fillForm]);

  const Cancel = () => {
    if (newTable) {
      const res = window.confirm(
        "Are you sure you want to cancel this entity?"
      );
      if (!res) {
        return;
      }
      handleDeleteTableEntity(entity!);
    }
    if (CellManage.entity) {
      CellManage.revertChanges();
    }
    Close(true);
  };
  const onSubmit = (data: any) => {
    if (!entity) {
      throw new Error("Entity is not defined");
    }
    if (entity instanceof Transshipment && data.pod && data.pol) {
      setError("pod", { message: "Transshipment cannot have POD and POL" });
      setError("pol", { message: "Transshipment cannot have POD and POL" });
      return;
    }
    entity.name = data.name;
    delete data.name;
    if ("Routes Table" in data) {
      (entity as Transshipment).routes = data["Routes Table"];
      delete data["Routes Table"];
    }
    entity.resetGlobals();
    for (const key of Object.keys(data)) {
      if (Array.isArray(data[key])) {
        for (const d of data[key]) {
          entity.submitHeader(d);
        }
      } else if (data[key]) {
        entity.submitHeader(data[key]);
      }
    }
    Next();
    handleAddTableEntitySubmit(entity);
    setState("HEADERS");
    reset();
  };

  const validateName = (name: string) => {
    if (tableEntities.some((e) => e.name === name && e.id !== entity?.id)) {
      return "Table Name already exists";
    }
    return true;
  };

  const enableSave = entity?.isValid();
  const buttons = [
    <Button onClick={Cancel}>Cancel</Button>,
    <SaveButton disabled={!enableSave} onClick={() => Close()}>
      Done with Table
    </SaveButton>,
  ];
  return (
    <RightSideModal open={open} handleClose={handleClose} buttons={buttons}>
      <Stack spacing={2}>
        <Stack
          direction={"row"}
          spacing={1}
          sx={{
            height: 3,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {Array.from({ length: 3 }).map((_, index) => (
            <Step key={index} bgcolor={index <= step ? "#188038" : "#e0e0e0"} />
          ))}
        </Stack>
        <>
          {step === 0 && (
            <>
              <CustomAlert title="First Step - Set you table">
                Now you can go ahead and start tagging the headers of the table
              </CustomAlert>
              <Stack
                spacing={2}
                sx={{
                  p: 2,
                  border: 1,
                  borderRadius: 1,
                  borderColor: BORDER_COLOR,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Hanken Grotesk",
                    fontSize: "0.95rem",
                    fontWeight: 600,
                  }}
                >
                  Table Settings
                </Typography>
                <TextInput
                  label="Table Name"
                  control={control}
                  fieldName="name"
                  rules={{
                    required: "Table Name is mandatory!",
                    validate: validateName,
                  }}
                />
                {entity?.type === "Transshipment" && (
                  <>
                    <Typography
                      sx={{
                        fontFamily: "Hanken Grotesk",
                        fontSize: "0.80rem",
                        fontWeight: 500,
                      }}
                    >
                      Routes Table
                    </Typography>
                    <SelectInput
                      options={tableEntities.filter(
                        (e) => e.type === "Routes Table"
                      )}
                      control={control}
                      fieldName="Routes Table"
                      label="Routes Table"
                      getOptionLabel={(option: CellEntity) =>
                        `${option.name} ${option.coordinate}`
                      }
                      isOptionEqualToValue={(option, value: CellEntity) =>
                        option.id === value.id
                      }
                      rules={{ required: "Define Route Table!" }}
                    />
                  </>
                )}
                <Typography
                  sx={{
                    fontFamily: "Hanken Grotesk",
                    fontSize: "0.80rem",
                    fontWeight: 500,
                  }}
                >
                  Globals Values
                </Typography>
                {Object.entries(globalsMemo).map(
                  ([key, { isMulti, options, label }]: any) => (
                    <React.Fragment key={key}>
                      <SelectInput
                        options={options}
                        label={label}
                        control={control}
                        fieldName={key}
                        isMulti={isMulti}
                        groupBy={(option) => option.sheetName}
                        getOptionLabel={(option: CellEntity) =>
                          `${option.name} ${option.coordinate}`
                        }
                        isOptionEqualToValue={(option, value: CellEntity) =>
                          option.id === value.id
                        }
                      />
                    </React.Fragment>
                  )
                )}
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Button onClick={handleSubmit(onSubmit)} sx={{ ms: "auto" }}>
                    Next
                  </Button>
                </Box>
              </Stack>
            </>
          )}
          {step === 1 && (
            <>
              {!gotIt && (
                <CustomAlert title="Next Step - Headers">
                  Now you can go ahead and start tagging the headers of the
                  table
                  <Box sx={{ display: "flex", justifyContent: "end", mt: 3 }}>
                    <Button color="inherit" onClick={() => setGotIt(true)}>
                      Got it
                    </Button>
                  </Box>
                </CustomAlert>
              )}
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      color: "#188038",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AssistantIcon sx={{ mr: 1.5 }} />
                    Headers
                  </Typography>
                  <SaveButton
                    startIcon={<ArrowBackIcon />}
                    onClick={() => {
                      setState("NONE");
                      setStep(0);
                      setTimeout(() => fillForm(), 0);
                    }}
                  >
                    Return
                  </SaveButton>
                </Box>
                {CellManage.entity && ["HEADERS", "FIELDS"].includes(state) && (
                  <Stack
                    spacing={2}
                    sx={{
                      p: 2,
                      border: 1,
                      borderRadius: 1,
                      borderColor: BORDER_COLOR,
                    }}
                  >
                    <ManageCellForm />
                  </Stack>
                )}
              </>
            </>
          )}
          {step === 2 && (
            <CustomAlert title="Well done">You tagged a table</CustomAlert>
          )}
        </>
      </Stack>
    </RightSideModal>
  );
};

const CustomAlert = ({ title, children }: any) => {
  return (
    <Alert
      severity="success"
      sx={{ color: "#188038", bgcolor: "#E6F4EA" }}
      icon={<AssistantIcon />}
    >
      <AlertTitle sx={{ fontWeight: "bold" }}>{title}</AlertTitle>
      {children}
    </Alert>
  );
};

const SaveButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#E7F3EB",
  color: "#48AA6B",
}));
const Step = styled(Box)(({ width }) => ({
  height: "100%",
  width: `100%`,
  borderRadius: 0,
}));

export default ManageTableModal;
