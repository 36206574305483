import { TreeViewBaseItem } from "@mui/x-tree-view";
import { ExtendedTreeItemProps } from "src/components/TreeView";
import { Range } from "src/types";
import { Field } from "../Fields";
import { Entity } from "./Entity";
import { TableEntity } from "./TableEntity";

export class CellEntity extends Entity {
  global: boolean;
  parentTable?: TableEntity;
  override?: boolean;
  constructor(
    id: string,
    range: Range,
    type: string,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE",
    global: boolean = false,
    parentTable?: TableEntity,
    override?: boolean
  ) {
    super(id, range, type, sheetIndex, coordinate, sheetName, name, status);
    this.global = global;
    this.parentTable = parentTable;
    this.override = override;
  }

  public initOverride(): void {
    if (this.override) {
      for (const field of this.getFields()) {
        field.required = false;
      }
    }
  }

  public getFields(): Field[] {
    return [];
  }

  public static globals(): string[] {
    const res = [
      "Condition",
      "Currency",
      "POL",
      "POD",
      "Comment",
      "Validity start",
      "Validity end",
      "General Fee",
      "Free Days(POL)",
      "Free Days(POD)",
    ];
    if (Entity.FILE_TYPE !== "FCL") {
      res.push("Volumetric Ratio");
    }
    return res;
  }

  public getName(): string {
    return this.global ? `${this.name} ${this.coordinate}` : this.coordinate;
  }

  public toTreeItem(
    parentId?: string
  ): TreeViewBaseItem<ExtendedTreeItemProps> {
    return {
      id: parentId ? `${parentId}-${this.id}` : this.id,
      label: this.getName(),
      type: "ENTITY",
      parentId: parentId ?? `${this.type}-global`,
      entity: this,
      allowEdit: this.global && parentId ? false : true,
    };
  }

  public toJson(): any {
    return {
      id: this.id,
      sheetIndex: this.sheetIndex,
      sheetName: this.sheetName,
      row: this.range.r,
      col: this.range.c,
      scope: this.global ? "global" : "local",
      metadata: {
        type: this.type,
        name: this.name,
        status: this.status,
        coordinate: this.coordinate,
        range: this.range,
        override: this.override,
        global: this.global,
      },
    };
  }

  public toDepndency(): any {
    return { entityId: this.id, type: Entity.Mapper[this.type] };
  }

  public fromJson(json: any, map: Map<string, Entity>): void {
    this.initOverride();
    if (this.override) {
      this.parentTable?.addOverride(this);
    }
  }
}
